<template>
    <!-- 页面主体区域 -->
    <el-container class="ship_all">
        <el-main style="padding: 0">
            <div class="infobut">
                <el-button @click="drawer = true" type="info">
                    查看船期信息
                </el-button>
            </div>
            <div class="bac">
                <div id="mapDivwx" class="map"></div>
            </div>
            <el-drawer
                title="船期"
                :visible.sync="drawer"
                :direction="direction"
                :with-header="false"
                size="340px"
            >
                <el-card>
                    <div slot="header" class="clearfix">
                        <span class="title">船期</span>
                        <el-button
                            type="info"
                            plain
                            size="small"
                            @click="getShipTrack()"
                        >
                            七日船轨迹查询
                        </el-button>
                    </div>
                    <div class="ship_tips_hight">
                        <div
                            v-for="(value, key, index) in shipData"
                            :key="index"
                            class="cardfont"
                        >
                            {{ key }} :
                            <div class="spans"></div>
                            {{ value }}
                        </div>
                    </div>
                </el-card>
            </el-drawer>
        </el-main>
    </el-container>
</template>
<script>
import pako from "pako";
import Moment from "moment";
import { getAisInfo, shipSearch, getTrack } from "@/api/ship";
import { apiCallback } from "@/utils";
import { mapTypeSelect } from "@/utils/dataList";
export default {
    data() {
        return {
            mapTypeSelect,
            chuan1: require("@/assets/img/chuan1.png"),
            lvyuan: require("@/assets/img/1.svg"),
            zoom: 6,
            map: null,
            mapclick: null,
            ship: "",
            mmsi: "",
            zuobiaox: "",
            zuobiaoy: "",
            key1: 1,
            options: [],
            value: "",
            isShipTrack: false,
            ShipTrackData: [],
            AisInfoData: [],
            allShipTrackData: [],
            shipmmsi: [],
            dist: {
                id: 0,
                type: 1,
                cnname: 2,
                name: 3,
                g: 4,
                len: 5,
                wid: 6,
                dpth: 7,
                a: 8,
                b: 9,
                c: 10,
                d: 11,
                lon: 12,
                lat: 13,
                time: 14,
                spd: 15,
                from: 16,
                status: 17,
                hdg: 18,
                cog: 19,
                mt: 20,
                postype: 21,
                pt: 22,
                ct: 23,
                build: 24,
                callsign: 25,
                imo: 26,
                dest: 27,
                eta: 28,
                diff: 29,
                firm: 30,
                gt: 31,
            },
            shipData: {
                mmsi: "",
                船名: "",
                呼号: "",
                当前船速: "",
                航行状态: "",
                imo: "",
                目的地: "",
                预计到达时间: "",
                更新时间: "",
            },
            aisdata: {
                result: 'f({"status":0,"notall":0,"points":[{"datatype":0,"utc":1656130115,"lon":121255080,"lat":31631290,"sog":3910,"cog":31550},{"datatype":0,"utc":1656130831,"lon":121224187,"lat":31651220,"sog":3601,"cog":27080},{"datatype":0,"utc":1656130852,"lon":121216160,"lat":31651377,"sog":2264,"cog":29220},{"datatype":0,"utc":1656131653,"lon":121209800,"lat":31653063,"sog":0,"cog":36000},{"datatype":0,"utc":1656131831,"lon":121209800,"lat":31653050,"sog":0,"cog":36000},{"datatype":0,"utc":1656133810,"lon":121209840,"lat":31653137,"sog":0,"cog":36000},{"datatype":0,"utc":1656135789,"lon":121209787,"lat":31653073,"sog":0,"cog":36000}]})',
            },
            huoche: require("@/assets/img/chuan3.png"),
            drawer: false,
            direction: "btt",
        };
    },
    mounted() {
        this.Init();
    },
    methods: {
        Init() {
            this.initmaker();
        },
        initmaker() {
            // 参数获取
            this.ship = this.$route.query.vesselEname;
            this.AisInfoData = this.$store.state.ship.AisInfoData || [];
            for (var i = 0; i < this.AisInfoData.length; i++) {
                var timestamp = new Date().valueOf();
                if (this.AisInfoData[i][1] < timestamp - 1200 * 1000) {
                    this.AisInfoData.splice(i, 1);
                    i--;
                }
            }
            this.allShipTrackData = this.$store.state.ship.shipTrackData || [];

            for (var i = 0; i < this.allShipTrackData.length; i++) {
                var createdAt = Object.values(this.allShipTrackData[i])[0][0][
                    "createdAt"
                ];
                var timestamp = new Date().valueOf();
                if (parseInt(createdAt) < timestamp - 1200 * 1000) {
                    this.allShipTrackData.splice(i, 1); // 将使后面的元素依次前移，数组长度减1
                    i--; // 如果不减，将漏掉一个元素
                }
            }
            this.findShip2();
        },

        findShip2() {
            shipSearch({ type: "auto", vesselsName: this.ship })
                .then((res) => {
                    const result = apiCallback(res);
                    var mmsi = "";
                    if (result) {
                        if (result.length == 1) {
                            mmsi = result[0].m;
                        } else {
                            result.forEach((item) => {
                                const searchShipName = this.ship.replaceAll(
                                    " ",
                                    "",
                                );
                                const shipName = item.n.replaceAll(" ", "");
                                if (
                                    item.t == 100 &&
                                    searchShipName == shipName
                                ) {
                                    mmsi = item.m;
                                }
                            });
                        }
                    }
                    if (mmsi != "") {
                        this.mmsi = mmsi;
                        this.dealFindship(mmsi);
                    } else {
                        this.$message.error("无该船期信息");
                    }
                })
                .catch((err) => console.error(err));
        },

        dealFindship(data) {
            var that = this;
            var mmsi = data;
            var itemdata = [];
            if (
                this.AisInfoData &&
                this.AisInfoData.find((item) => {
                    itemdata = item;
                    if (item.length) {
                        return item[0][0] == mmsi;
                    }
                })
            ) {
                this.dealShipInfo(itemdata);
                if (this.zuobiaox == null || this.zuobiaoy == null) {
                    this.$message.error("无该船坐标信息");
                } else {
                    this.addflag(this.zuobiaox, this.zuobiaoy);
                    this.isShipTrack = false;
                }
            } else {
                this.getShipInfo(mmsi);
            }
            setTimeout(function () {
                that.getShipTrack();
            }, 1000);
        },
        getShipTrack() {
            var mmsi = this.mmsi;
            var itemdata = [];
            if (
                this.allShipTrackData &&
                this.allShipTrackData.find((item) => {
                    itemdata = item;
                    return Object.keys(item)[0] == mmsi;
                })
            ) {
                this.saveallShipTrackData(this.allShipTrackData);
                this.ShipTrackData = itemdata[mmsi];
                this.ShowTrack(this.ShipTrackData, this.zoom);
                this.isShipTrack = true;
            } else {
                this.ShipTrack();
            }
        },
        ShipTrack() {
            const that = this;
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            getTrack({
                mmsi: this.mmsi,
                start: start.valueOf(),
                end: end.valueOf(),
            })
                .then(async (res) => {
                    const data = apiCallback(res);
                    const result = data.data;
                    if (result && result !== null) {
                        var base64 = this.unzip(result);
                        if (
                            !this.allShipTrackData.find((item) => {
                                return (
                                    Object.keys(item)[0] ==
                                    Object.keys(base64)[0]
                                );
                            })
                        ) {
                            this.allShipTrackData.push(base64);
                        }
                        this.saveallShipTrackData(this.allShipTrackData);
                        this.ShipTrackData = base64[that.mmsi];
                        this.ShowTrack(this.ShipTrackData, this.zoom);
                        this.isShipTrack = true;
                    }
                })
                .catch((err) => console.error(err));
        },

        getShipInfo(data) {
            getAisInfo({ mmsi: data })
                .then((res) => {
                    const data = apiCallback(res);
                    const result = data.data;
                    if (result && result !== null) {
                        let base64 = this.unzip(result);
                        base64.push(data.time);
                        if (this.AisInfoData.length) {
                            if (
                                !this.AisInfoData.find((item) => {
                                    return item[0][0] == base64[0][0];
                                })
                            ) {
                                this.AisInfoData.push(base64);
                            }
                        } else {
                            this.AisInfoData.push(base64);
                        }

                        this.saveAisInfo(this.AisInfoData);
                        this.dealShipInfo(base64);
                        if (this.zuobiaox == null || this.zuobiaoy == null) {
                            this.$message.error("无该船坐标信息");
                        } else {
                            this.addflag(this.zuobiaox, this.zuobiaoy);
                            this.isShipTrack = false;
                        }
                    }
                })
                .catch((err) => console.error(err));
        },
        dealShipInfo(base64) {
            this.zuobiaox = base64[0][this.dist["lon"]];
            this.zuobiaoy = base64[0][this.dist["lat"]];
            this.shipData.mmsi = base64[0][this.dist["id"]];
            this.shipData.船名 = base64[0][this.dist["name"]];
            this.shipData.呼号 = base64[0][this.dist["callsign"]];
            this.shipData.当前船速 = base64[0][this.dist["spd"]];
            this.shipData.航行状态 = base64[0][this.dist["status"]];
            this.shipData.imo = base64[0][this.dist["imo"]];
            this.shipData.目的地 = base64[0][this.dist["dest"]];
            this.shipData.预计到达时间 = base64[0][this.dist["eta"]];
            this.shipData.更新时间 = this.getFullTime(
                base64[0][this.dist["ct"]],
            ); //new Date(base64[0][this.dist['ct']])
        },
        saveAisInfo(data) {
            // setStorage 封装了 localStorage
            this.$store.commit("ship/editAisInfoData", data);
        },
        saveallShipTrackData(data) {
            // setStorage 封装了 localStorage
            // console.log(JSON.stringify(data))
            this.$store.commit("ship/editShipTrackData", data);
        },
        ShowTrack(data, zoom) {
            var lineArr = [];
            if (data.length) {
                for (var i = 0; i < data.length; i = i + 1) {
                    lineArr.push(new T.LngLat(data[i]["lon"], data[i]["lat"]));
                }
            }
            var times = Math.ceil(data.length / (zoom * zoom));
            if (this.map) {
                this.lineArr = lineArr;
                //创建线对象
                var line = new T.Polyline(lineArr, {
                    color: "#b8f5ff",
                    weight: 2,
                    lineStyle: "dashed",
                });
                //向地图上添加线
                this.map.addOverLay(line);
                //创建图片对象
                this.icon = new T.Icon({
                    iconUrl: this.huoche,
                    iconSize: new T.Point(80, 80),
                    iconAnchor: new T.Point(10, 25),
                });

                var content = [
                    `<div class="text-div">${this.shipData.船名}</div>`,
                ];
                var latlng = new T.LngLat(this.zuobiaox, this.zuobiaoy);
                var label = new T.Label({
                    text: content.join("<br>"),
                    position: latlng,
                    offset: new T.Point(-5, -20),
                });
                //创建地图文本对象
                this.map.addOverLay(label);
                //创建点图片对象
                var imgIcon = new T.Icon({
                    iconUrl:
                        "https://a.amap.com/jsapi_demos/static/images/mass0.png",
                    iconSize: new T.Point(20, 20),
                    iconAnchor: new T.Point(10, 25),
                });
                if (this.map.getZoom() >= 10) {
                    for (var i = 0; i < data.length; i = i + times) {
                        var lonlat = [];
                        var potins = {};
                        lonlat[0] = parseFloat(data[i]["lon"]); // 1000000
                        lonlat[1] = parseFloat(data[i]["lat"]); // 1000000
                        potins.name = this.timeConvert(data[i]["createdAt"]);
                        if (i % (3 * times) == 0) {
                            //向地图上添加自定义点标注
                            var marker = new T.Marker(
                                new T.LngLat(lonlat[0], lonlat[1]),
                                {
                                    icon: imgIcon,
                                    zIndexOffset: 5,
                                    title: potins.name,
                                },
                            );
                            // 添加文字标注
                            var label = new T.Label({
                                text: potins.name,
                                position: new T.LngLat(lonlat[0], lonlat[1]),
                                offset: new T.Point(-9, 0),
                            });
                            label.setFontSize(6);
                            label.setFontColor("#99aedd");
                            label.setBackgroundColor("#f5f5f5");
                            label.setOpacity(0.5);
                            this.map.addOverLay(marker);
                            this.map.addOverLay(label);
                        }
                    }
                } else {
                    this.initFlagLines();
                }
            }
        },
        // 比例调整之后需要清除所有覆盖物，保留船标和线
        initFlagLines() {
            if (this.map) {
                // 清除地图所有覆盖物
                this.map.clearOverLays();
                //创建图片对象
                var icon = new T.Icon({
                    iconUrl: this.huoche,
                    iconSize: new T.Point(50, 50),
                    iconAnchor: new T.Point(10, 25),
                });
                //创建标注对象
                var marker = new T.Marker(
                    new T.LngLat(this.zuobiaox, this.zuobiaoy),
                    {
                        icon: icon,
                    },
                );
                //向地图上添加标注
                this.map.addOverLay(marker);
                // 添加标注
                var content = [
                    `<div class="text-div">${this.shipData.船名}</div>`,
                ];
                var latlng = new T.LngLat(this.zuobiaox, this.zuobiaoy);
                var label = new T.Label({
                    text: content.join("<br>"),
                    position: latlng,
                    offset: new T.Point(-5, -20),
                });
                //创建地图文本对象
                this.map.addOverLay(label);
                //创建线对象
                var line = new T.Polyline(this.lineArr, {
                    color: "#b8f5ff",
                    weight: 2,
                    lineStyle: "dashed",
                });
                //向地图上添加线
                this.map.addOverLay(line);
            }
        },
        timeConvert(timestamp) {
            //num:0 YYYY-MM-DD  num:1  YYYY-MM-DD hh:mm:ss // timestamp:时间戳
            //将时间戳转换成正常时间格式
            var date = new Date(parseInt(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + "-";
            var M =
                (date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1) + "-";
            var D = date.getDate() + " ";
            var h = date.getHours() + ":";
            var m =
                (date.getMinutes() < 10
                    ? "0" + date.getMinutes()
                    : date.getMinutes()) + ":";
            var s = date.getSeconds();
            return Y + M + D + h + m + s;
        },
        unzip(data) {
            let charData = atob(data)
                .split("")
                .map(function (x) {
                    return x.charCodeAt(0);
                });
            let binData = new Uint8Array(charData);
            return JSON.parse(pako.inflate(binData, { to: "string" }));
        },
        getFullTime(timeStamp) {
            const stamp = new Date(timeStamp);
            const time = Moment(stamp).format("YYYY-MM-DD HH:mm:ss");

            return time;
        },
        addflag(longitude, latitude) {
            var that = this;
            that.map = new T.Map("mapDivwx", {projection: "EPSG:4326",minZoom: 4, maxZoom: 15 });
            let map = that.map;
            that.map.centerAndZoom(new T.LngLat(longitude, latitude), that.zoom);
            //创建图片对象
            var icon = new T.Icon({
                iconUrl: that.huoche,
                iconSize: new T.Point(40, 40),
                iconAnchor: new T.Point(10, 25),
            });
            //创建标注对象
            var marker = new T.Marker(new T.LngLat(longitude, latitude), {
                icon: icon,
            });
            //向地图上添加标注
            map.addOverLay(marker);
            //创建地图图层对象
            var ctrl = new T.Control.MapType({ mapTypes: that.mapTypeSelect }); // 初始化地图类型选择控件
            map.addControl(ctrl); //添加地图选择控件
            // //移动图层
            // map.removeControl(window.TMAP_NORMAL_MAP);
            // //添加图层
            // map.addControl(window.TMAP_HYBRID_MAP);
            // 添加标注
            var content = [`<div class="text-div">${that.shipData.船名}</div>`];
            var latlng = new T.LngLat(longitude, latitude);
            var label = new T.Label({
                text: content.join("<br>"),
                position: latlng,
                offset: new T.Point(-5, -20),
            });
            //创建地图文本对象
            map.addOverLay(label);
            map.on("zoomend", function () {
                var center = new T.LngLat(longitude, latitude);
                // 将地图平滑移动到指定位置
                map.panTo(center);
                if (that.isShipTrack) {
                    that.ShowTrack(that.ShipTrackData, map.getZoom());
                }
            });
        },
        getString2JsonArr(data) {
            var str = data.slice(2);
            str = str.slice(0, str.length - 1);
            var json = JSON.parse(str);
            return json;
        },
    },
};
</script>

<style lang="stylus" scoped>
.ship_all {
  min-height: 100vh;
}

.map {
  z-index: 0;
  height: 100%;
  width: 100%;
  background-color: bisque;
  display: flex;
}

.bac {
  /* z-index: 0; */
  height: 100%;
  width: 100%;
  /* background-color: bisque; */
  display: flex;
}

.mapbox {
  height: 650px;
  width: 300px;
  /* background-color: rgb(137, 201, 238); */
  margin-top: 20px;
  margin-left: 10px;
  /* transform: translate(-300px, 0px); */
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
}

.infobut {
  margin-top: 10px;
  margin-left: 5px;
  z-index: 1;
  position: absolute;
}

.input {
  height: 50px;
  width: 300px;
  background-color: #dadada;
  margin-top: 50px;
  margin-left: 10px;
  /* transform: translate(-800px, 100px); */
  z-index: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.ship_tips_hight {
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
}

.cardfont {
  font-size: 12px;
  display: flex;
  /* flex-direction: row; */
  /* align-items: flex-end; */
  /* margin-bottom: 3rem; */
}

.spans {
  width: 20px;
}

.title {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  user-select: none;
}

.text-div {
  /* background-color: bisque; */
  font-size: 14px;
}

.texttime-div {
  /* background-color: bisque; */
  font-size: 10px;
}

/deep/.tdt-infowindow-content-wrapper, .tdt-infowindow-tip {
  background-color: rgba(248, 248, 255, 0.6);
  color: #333;
  box-shadow: 0 3px 14px rgb(0 0 0 / 40%);
}

/deep/.tdt-container a.tdt-infowindow-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px / 14px Tahoma, Verdana, sans-serif;
  color: #543232;
  text-decoration: none;
  font-weight: 700;
  background: 0 0;
}

/deep/.tdt-label {
  background-color: rgba(248, 248, 255, 0.6);
  line-height: 16px;
  padding: 2px;
  -moz-box-shadow: 1px 1px 4px #999;
  -webkit-box-shadow: 1px 1px 4px #999;
  box-shadow: 1px 1px 4px #b59a9a;
}

.el-button--mini, .el-button--small {
  font-size: 12px;
  border-radius: 3px;
}

.clearfix {
  display: flex;
  justify-content: space-between;
}
.amap-icon {
    overflow: auto;
}
.amap-icon img {
    width: 100%;
    height: 100%;
}

/* el-button {
  width: 120px;
} */
</style>
